/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable eqeqeq */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReplaySubject, Subject } from 'rxjs';
import { LogLevel } from '@microsoft/signalr';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  static readonly serviceName = 'LogService';
  static readonly KEY = `${LogService.serviceName}.activatedKey`;
  private static _activated$: Subject<boolean> = new ReplaySubject<boolean>(1);
  private static _activated: boolean = environment.loglevel <= LogLevel.Information;


  public static get activated$(): Subject<boolean> {
    return LogService._activated$;
  }

  public static get activated(): boolean {
    return LogService._activated;
  }

  public static set activate(value: boolean) {
    environment.loglevel = (value) ? LogLevel.Information : LogLevel.Warning;

    window.localStorage.setItem(this.KEY, `${environment.loglevel <= LogLevel.Information}`);
    LogService._activated = environment.loglevel <= LogLevel.Information;
    LogService._activated$.next(LogService._activated);


  }

  public static initialize(constructorName: string): any {
    const logger = {};

    const activated = window.localStorage.getItem(LogService.KEY);

    if (activated !== null) {
      environment.loglevel = (activated === 'true') ? LogLevel.Information : LogLevel.Warning;
      LogService._activated = environment.loglevel <= LogLevel.Information;
    }

    if (environment.loglevel <= LogLevel.Information) {
      for (const m in console) {
        if (typeof (console as any)[m] == 'function') {
          (logger as any)[m] = (console as any)[m].bind(window.console, `${this.leadingChars(constructorName, '.', 27)}`);
        }
      }
    } else {
      for (const m in console) {
        if (typeof (console as any)[m] == 'function') {
          (logger as any)[m] = function () { };
        }
      }
    }

    return logger;
  }

  private static leadingChars(text: string, chars: string | number, length: number): string {
    return (text + chars.toString().repeat(length)).substring(0, length);
  }
}
