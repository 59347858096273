/**
 * CentrXDecision.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable max-len */


export type Brand = 'Arblockane' | 'Collanix' | 'Erablast' | 'Eraxinate' | 'Fibroxin' | 'Immuvan' | 'Ligarex' | 'Matrax' | 'MatraxWeekly' | 'Mobilon' | 'Mobilon6Month' | 'Nipress' | 'Palaxinol' | 'Sintalus' | 'Sopprax' | 'Traxinol';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Brand = {
    Arblockane: 'Arblockane' as Brand,
    Collanix: 'Collanix' as Brand,
    Erablast: 'Erablast' as Brand,
    Eraxinate: 'Eraxinate' as Brand,
    Fibroxin: 'Fibroxin' as Brand,
    Immuvan: 'Immuvan' as Brand,
    Ligarex: 'Ligarex' as Brand,
    Matrax: 'Matrax' as Brand,
    MatraxWeekly: 'MatraxWeekly' as Brand,
    Mobilon: 'Mobilon' as Brand,
    Mobilon6Month: 'Mobilon6Month' as Brand,
    Nipress: 'Nipress' as Brand,
    Palaxinol: 'Palaxinol' as Brand,
    Sintalus: 'Sintalus' as Brand,
    Sopprax: 'Sopprax' as Brand,
    Traxinol: 'Traxinol' as Brand
};