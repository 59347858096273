import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
/* eslint-disable-next-line max-len */
import { InitiativePrefixesBioPharmaInitiativeKeyBioPharmaInitiative, InsightBioPharma, MailKeyBioPharmaMail, MenuEntryKeyBioPharmaMenuEntry, StringKeyBioPharmaStringValue, ToolBioPharma } from 'src/app/generated/model/scenario';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BioStringApi {
  private readonly bn: string;

  constructor(
    private http: HttpClient) {
    this.bn = environment.formatBuildNumber(environment.buildNumber);
  }

  public getInitiatives(): Observable<InitiativePrefixesBioPharmaInitiativeKeyBioPharmaInitiative[]> {
    return this.http.get<InitiativePrefixesBioPharmaInitiativeKeyBioPharmaInitiative[]>(`./strings/BioPharma-Initiative.json?i=${this.bn}`);
  }

  public getInsights(): Observable<InsightBioPharma[]> {
    return this.http.get<InsightBioPharma[]>(`./strings/BioPharma-Insight.json?i=${this.bn}`);
  }

  public getMails(): Observable<MailKeyBioPharmaMail[]> {
    return this.http.get<MailKeyBioPharmaMail[]>(`./strings/BioPharma-Mailbox.json?i=${this.bn}`);
  }

  public getMenuEntries(): Observable<MenuEntryKeyBioPharmaMenuEntry[]> {
    return this.http.get<MenuEntryKeyBioPharmaMenuEntry[]>(`./strings/BioPharma-Menu.json?i=${this.bn}`);
  }

  public getStringValues(): Observable<StringKeyBioPharmaStringValue[]> {
    return this.http.get<StringKeyBioPharmaStringValue[]>(`./strings/BioPharma-StringValue.json?i=${this.bn}`);
  }

  public getTools(): Observable<ToolBioPharma[]> {
    return this.http.get<ToolBioPharma[]>(`./strings/BioPharma-Tool.json?i=${this.bn}`);
  }
}
