/* eslint-disable max-len */
import { MatTableDataSource } from '@angular/material/table';
import { RowTableSource } from '../model/RowTableSource';
import { TableSource } from '../model/TableSource';

export class TableElement {

  private columnsList: string[] = [];
  private totalTable: number = 0;
  private rowsList: RowTableSource[] = [];
  private dataBackFrontList: TableSource[] = [];

  constructor() { }

  public initColumnsAndRows(dataColumns: string[], rows: RowTableSource[]): void {
    this.columnsList = dataColumns;
    this.rowsList = rows;
  }

  public buildTablePercentBackFrontList(dataContentTable: number[][]): void {
    this.dataBackFrontList = [];
    this.totalTable = 0;
    dataContentTable?.forEach((row, rowIndex) => {
      const elementColumns: any = {};
      this.columnsList.forEach((columnToAdd, index) => {
        this.totalTable += dataContentTable[rowIndex][index];
        elementColumns[columnToAdd] = {
          rate: row[index],
        };
      });
      const elementRow: TableSource = {
        title: this.rowsList[rowIndex].title,
        unity: this.rowsList[rowIndex].unity,
        picture: this.rowsList[rowIndex].picture,
        team: this.rowsList[rowIndex].team,
        columns: elementColumns
      };
      if (this.rowsList[rowIndex].class) {
        elementRow.class = this.rowsList[rowIndex].class;
      }
      this.dataBackFrontList.push(elementRow);
    });
    this.totalTable = this.totalTable / 2;
  }

  public buildComplexBackFrontList(
    dataContentTable: ((number | null)[][] | undefined),
    rateContentTable: ((number | null)[][] | undefined),
    minContentTable: ((number | null)[][] | undefined),
    maxContentTable: ((number | null)[][] | undefined),
    stringContentTable: ((string | null)[][] | undefined) = undefined,
    highlightContentTable: ((any | null)[][] | undefined) = undefined,
    displayMinMax: boolean = true
  ): void {
    this.dataBackFrontList = [];
    this.totalTable = maxContentTable ? (maxContentTable?.at(-1)?.at(-1) ?? 0) : 0;

    const nbRows = dataContentTable ? dataContentTable.length :
      (rateContentTable ? rateContentTable.length : (stringContentTable ? stringContentTable.length : 0));

    for (let rowIndex: number = 0; rowIndex < nbRows; rowIndex++) {
      const elementColumns: any = {};
      this.columnsList.forEach((columnToAdd, columnIndex) => {
        if (!maxContentTable && dataContentTable) {
          let total = 0;
          this.totalTable += dataContentTable[rowIndex][columnIndex] ?? 0;
          for (const rowTable of dataContentTable) {
            total += rowTable[columnIndex] ?? 0;
          }
          total = total / 2;
        }

        let isHighlightedCell: boolean = false;
        let vdata: (number | null) = null;
        let vhighlightdata: (number | null) = null;
        if (dataContentTable) {
          if (highlightContentTable && highlightContentTable[rowIndex][columnIndex] === 1) {
            isHighlightedCell = true;
            vhighlightdata = dataContentTable[rowIndex][columnIndex];
          } else {
            vdata = dataContentTable[rowIndex][columnIndex];
          }
        }

        let vrate = rateContentTable ? rateContentTable[rowIndex][columnIndex] : null;
        const noRate = vrate === null;
        const vmin = minContentTable ? minContentTable[rowIndex][columnIndex] : null;
        const vmax = maxContentTable ? maxContentTable[rowIndex][columnIndex] : null;
        const vtext = stringContentTable ? stringContentTable[rowIndex][columnIndex] : null;
        if (vrate === null && vmax !== null && ((vdata !== null) || (vhighlightdata !== null))) {
          if (vmin === vmax) {
            vrate = 50;
          } else if ((vdata ?? vmax) > vmax) {
            vrate = 100;
          } else if ((vdata ?? (vmin ?? 0)) < (vmin ?? 0)) {
            vrate = 0;
          } else {
            if (vdata !== null) {
              vrate = (vdata - (vmin ?? 0)) * 100 / (vmax - (vmin ?? 0));
            } else if (vhighlightdata !== null) {
              vrate = (vhighlightdata - (vmin ?? 0)) * 100 / (vmax - (vmin ?? 0));
            }
          }
        }
        const isData = vdata !== null;
        const isHData = vhighlightdata !== null;

        const isRate = vrate !== null;
        const isMin = vmin !== null;
        const isMax = vmax !== null;

        const isText = vtext !== null;

        if (!isRate) {
          if (isData) {
            elementColumns[columnToAdd] = vdata;
          } else if (isHData) {
            elementColumns[columnToAdd] = { highlightedData: vhighlightdata };
          } else if (isText) {
            elementColumns[columnToAdd] = isHighlightedCell ? { highlightedData: vtext } : vtext;
          }
        } else {
          elementColumns[columnToAdd] = {
            data: dataContentTable && isData ? dataContentTable[rowIndex][columnIndex] : undefined,
            rate: isRate ? (vrate ?? 0) : undefined,
            highlightedData: isHData ? vhighlightdata : undefined,
            min: (isMin && displayMinMax) ? vmin : undefined,
            max: (isMax && displayMinMax) ? vmax : undefined,
            text: isText ? (isHighlightedCell ? { highlightedData: vtext } : vtext) : undefined,
            showRate: !noRate
          };
        }
      });
      const elementRow: TableSource = {
        title: this.rowsList[rowIndex].title,
        unity: this.rowsList[rowIndex].unity,
        picture: this.rowsList[rowIndex].picture,
        team: this.rowsList[rowIndex].team,
        columns: elementColumns
      };
      if (this.rowsList[rowIndex].class) {
        elementRow.class = this.rowsList[rowIndex].class;
      }
      this.dataBackFrontList.push(elementRow);
    }
    if (!maxContentTable) {
      this.totalTable = this.totalTable / 2;
    }
  }

  public buildTripleBackFrontList(dataContentTable: number[][], rateContentTable?: number[][], totalContentTable?: number[][]): void {
    this.dataBackFrontList = [];
    this.totalTable = totalContentTable ? (totalContentTable?.at(-1)?.at(-1) ?? 0) : 0;
    dataContentTable?.forEach((row, rowIndex) => {
      const elementColumns: any = {};
      this.columnsList.forEach((columnToAdd, columnIndex) => {
        let total = 0;
        if (!totalContentTable) {
          this.totalTable += dataContentTable[rowIndex][columnIndex];
          for (const rowTable of dataContentTable) {
            total += rowTable[columnIndex];
          }
          total = total / 2;
        } else {
          total = totalContentTable[rowIndex][columnIndex];
        }

        elementColumns[columnToAdd] = {
          data: row[columnIndex],
          rate: rateContentTable ? rateContentTable[rowIndex][columnIndex] : ((row[columnIndex] / total) * 100),
          total
        };
      });
      const elementRow: TableSource = {
        title: this.rowsList[rowIndex].title,
        unity: this.rowsList[rowIndex].unity,
        picture: this.rowsList[rowIndex].picture,
        team: this.rowsList[rowIndex].team,
        columns: elementColumns
      };
      if (this.rowsList[rowIndex].class) {
        elementRow.class = this.rowsList[rowIndex].class;
      }
      this.dataBackFrontList.push(elementRow);
    });
    if (!totalContentTable) {
      this.totalTable = this.totalTable / 2;
    }
  }

  public buildHighlightedData(contentTable: number[][], outRows: number[]): number[][] {
    const contentHighlightedData: number[][] = [];
    if (contentTable[0]) {
      for (let i = 0; i < contentTable[0].length; i++) {
        let max = 0;
        for (let j = 0; j < contentTable.length; j++) {
          if (!contentHighlightedData[j]) {
            contentHighlightedData[j] = [];
          }
          if (contentTable[j][i] > max && outRows.indexOf(j) === -1) {
            max = contentTable[j][i];
          }
        }
        for (let j = 0; j < contentTable.length; j++) {
          if (contentTable[j][i] === max) {
            contentHighlightedData[j][i] = 1;
          } else {
            contentHighlightedData[j][i] = 0;
          }
        }
      }
    }
    return contentHighlightedData;
  }

  public buildBackFrontList(dataContentTable: (number | null)[][], highlightTable?: (number | null)[][], checkUnits: boolean = true): void {
    this.dataBackFrontList = [];
    dataContentTable?.forEach((row, rowIndex) => {
      const elementColumns: any = {};
      this.columnsList.forEach((columnToAdd, index) => {
        const value = row[index] !== null ? row[index] : undefined;
        if (highlightTable && highlightTable[rowIndex][index] === 1) {
          elementColumns[columnToAdd] = {
            highlightedData: value
          };
        } else {
          if (checkUnits === false) {
            elementColumns[columnToAdd] = value;
          }
          else if (value !== undefined) {
            elementColumns[columnToAdd] = this.rowsList[rowIndex]?.unity?.includes('%') ? (value ?? 0).toFixed(1) : value;
          }
        }
      });
      const elementRow: TableSource = {
        title: this.rowsList[rowIndex].title,
        unity: this.rowsList[rowIndex].unity,
        picture: this.rowsList[rowIndex].picture,
        team: this.rowsList[rowIndex].team,
        columns: elementColumns,
        displayedUnity: checkUnits === false ? '' : (this.rowsList[rowIndex]?.unity?.includes('%') ? '%' : '')
      };
      if (this.rowsList[rowIndex].class) {
        elementRow.class = this.rowsList[rowIndex].class;
      }
      this.dataBackFrontList.push(elementRow);
    });
  }


  public addChange(highlightNegative: boolean = false): void {
    this.dataBackFrontList.forEach(element => {
      if (Number(element.columns[this.columnsList[this.columnsList.length - 2]] !== 0)) {
        if (this.columnsList.length - 2 < 0) {
          element.columns.Simulation_Change = '--';
        } else {
          const column2Value = element.columns[this.columnsList[this.columnsList.length - 1]]?.highlightedData ??
            element.columns[this.columnsList[this.columnsList.length - 1]];
          const column1Value = element.columns[this.columnsList[this.columnsList.length - 2]]?.highlightedData ??
            element.columns[this.columnsList[this.columnsList.length - 2]];
          const calcul = ((Number(column2Value) - Number(column1Value)) / Math.abs(Number(column1Value))) * 100;
          const displayCalcul = Math.round(calcul * 10) / 10;
          let value: string = '';
          if (element.displayedUnity !== '%') {
            value = displayCalcul + '%';
          }
          if (value !== 'NaN%' && Math.round(calcul * 100) > 0) {
            value = '+' + value;
          }
          if (value === 'NaN%') {
            element.columns.Simulation_Change = '--';
          } else {
            if (highlightNegative && calcul < 0) {
              element.columns.Simulation_Change = {
                highlightedData: value
              };
            } else {
              element.columns.Simulation_Change = value;
            }
          }
        }
      } else if (element.displayedUnity !== '%') {
        element.columns.Simulation_Change = '--';
      } else {
        element.columns.Simulation_Change = '--';
      }
    });
  }

  public addShare(columnIndex: number): void {
    let sum = 0;

    this.dataBackFrontList.forEach(element => {
      sum += Number(element.columns[this.columnsList[columnIndex]]?.highlightedData ??
        element.columns[this.columnsList[columnIndex]]);
    });
    this.dataBackFrontList.forEach(element => {
      const rowshare = Number(element.columns[this.columnsList[columnIndex]]?.highlightedData ??
        element.columns[this.columnsList[columnIndex]]) * 100 / sum;
      element.columns.Share = Math.round(rowshare * 10) / 10;
      if (element.displayedUnity !== '%') {
        element.columns.Share = element.columns.Share + '%';
      }
      if (element.columns.Share === 'NaN%') {
        element.columns.Share = '--';
      }
    });
  }

  public addTotalColumn(): void {
    this.columnsList.push('Total');
    this.dataBackFrontList.forEach(element => {
      if (element.columns) {
        let total = 0;
        const listColumnsValues = Object.keys(element.columns).map((key) => element.columns[key]);
        listColumnsValues.forEach((column) => {
          if (column && column.highlightedData) {
            total += Number(column.highlightedData);
          } else {
            total += Number(column);
          }
        });
        element.columns.Total = total;
      } else {
        element.columns.Total = 0;
      }
    });
  }

  public addTripleTotalColumn(): void {
    this.columnsList.push('Total');

    this.dataBackFrontList.forEach(element => {
      if (element.columns) {
        let total = 0;
        const listColumnsValues = Object.keys(element.columns).map((key) => element.columns[key]);
        listColumnsValues.forEach((column: any) => {
          total += Number(column.data);
        });
        total = total;
        element.columns.Total = {
          data: total,
          rate: this.totalTable ? ((total / this.totalTable) * 100) : 0,
          total: this.totalTable ?? 0
        };
      } else {
        element.columns.Total = 0;
      }
    });
  }

  public changeDataRate(totalElement: TableSource): void {
    this.dataBackFrontList.forEach(element => {
      element.displayedUnity = '%';
      if (totalElement) {
        this.columnsList.forEach((column: string) => {
          element.columns[column] = this.columnValue(element.columns[column]) * 100 / this.columnValue(totalElement.columns[column]);
        });
      }
    });
  }

  public elementTotalAndAverage(title: string, rows: string[], divideBy: number = 1, round: boolean = false, team?: string): TableSource {
    const elementRow: TableSource = {
      title,
      unity: '',
      //team: team,
      endColor: true,
      columns: {}
    };

    this.columnsList.forEach(column => {
      rows.forEach((rowId: string) => {
        const elementFound: TableSource | undefined = this.dataBackFrontList.find(element => (element.title === rowId));
        if (elementFound) {
          const columnValue = !this.columnValue(elementFound.columns[column]) ? 0 : this.columnValue(elementFound.columns[column]);
          if (!elementRow.columns[column]) {
            elementRow.columns[column] = round ? Math.round(columnValue / divideBy) : columnValue / divideBy;
          } else {
            elementRow.columns[column] = round ? Math.round(Number(elementRow.columns[column] ?? 0) + (columnValue / divideBy))
              : Number(elementRow.columns[column] ?? 0) + (columnValue / divideBy);
          }
        }
      });
    });
    return elementRow;
  }

  public elementTripleTotal(title: string, rows: string[], team?: string): TableSource {
    const elementRow: TableSource = {
      title,
      unity: '',
      //team: team,
      endColor: true,
      columns: {}
    };
    this.columnsList.forEach(column => {
      rows.forEach((rowId: string) => {
        const elementFound: TableSource | undefined = this.dataBackFrontList.find(element => (element.title === rowId));
        if (elementFound) {
          if (!elementRow.columns[column]) {
            elementRow.columns[column] = {
              data: elementFound.columns[column].data,
              rate: this.totalTable ? ((elementFound.columns[column].data / this.totalTable) * 100) : 0,
            };
            if (this.totalTable) {
              elementFound.columns[column].total = this.totalTable;
            }
          } else {
            elementRow.columns[column] = {
              data: elementRow.columns[column].data + (elementFound.columns[column].data),
              rate: this.totalTable ? (((elementRow.columns[column].data +
                (elementFound.columns[column].data)) / this.totalTable) * 100) : 0,
            };
            if (this.totalTable) {
              elementRow.columns[column].total = this.totalTable;
            }
          }
        }
      });
    });
    return elementRow;
  }

  public elementRateTotal(totalElement: TableSource): TableSource {
    const element: TableSource = {
      title: 'Total (in %)',
      unity: '',
      displayedUnity: '%',
      endColor: true,
      columns: {}
    };
    if (totalElement) {
      this.columnsList.forEach((column: string) => {
        element.columns[column] = this.columnValue(totalElement.columns[column] * 100) / this.columnValue(totalElement.columns.Total);
      });
    }
    return element;
  }

  public elementById(id: string | [string, string], subTableList?: RowTableSource[], unityId?: string, isExpanded?: boolean): TableSource {
    let elementId: string = '';
    let team: (string | undefined);
    if (typeof id === 'string') {
      elementId = id;
    } else {
      elementId = id[0];
      team = id[1];
    }

    let newElement: TableSource | undefined = this.dataBackFrontList.find(element => (element.title === elementId) &&
      (!unityId || (unityId && element.unity === unityId)) && (element.team === team));
    if (!newElement) {
      newElement = {
        title: elementId,
        unity: unityId ?? '',
        team,
        columns: {}
      };
    }
    if (subTableList) {
      const dataSubTable: TableSource[] = [];
      subTableList.forEach((row) => {
        const elementSource: TableSource | undefined = this.dataBackFrontList.find(element => (element.title === row.title) &&
          (!unityId || (unityId && element.unity === unityId)) && (element.team === row.team));
        if (elementSource) {
          dataSubTable.push(elementSource);
        }
      });
      newElement.isExpanded = isExpanded;
      newElement.expandedTable = new MatTableDataSource(dataSubTable);
    }
    return newElement;
  }

  // market-forecast
  elementGrowthById(elementName: string, elementId1: string, elementId2: string): TableSource {
    const element1: TableSource | undefined = this.dataBackFrontList.find(element => (element.title === elementId1));
    const element2: TableSource | undefined = this.dataBackFrontList.find(element => (element.title === elementId2));
    if (element1 && element2) {
      const element: TableSource = {
        title: elementName,
        unity: '',
        displayedUnity: '%',
        endColor: true,
        columns: {}
      };
      this.columnsList.forEach((column: string) => {
        element.columns[column] = ((this.columnValue(element2.columns[column])
          - this.columnValue(element1.columns[column])) / this.columnValue(element1.columns[column])) * 100;
      });
      return element;
    } else {
      return {
        title: elementName,
        unity: '',
        columns: {}
      };
    }
  }

  public toGrowthElement(element: TableSource): TableSource {
    this.columnsList.forEach((column: string) => {
      if (element.columns[column] > 0) {
        if (typeof element.columns[column] === 'number') {
          element.columns[column] = element.columns[column].toFixed(1);
        }
        element.columns[column] = '+' + element.columns[column];
      }
    });
    return element;
  }


  // market-forecast
  elementPowerById(elementName: string, elementId1: string, elementId2: string): TableSource {
    const element1: TableSource | undefined = this.dataBackFrontList.find(element => (element.title === elementId1));
    const element2: TableSource | undefined = this.dataBackFrontList.find(element => (element.title === elementId2));
    if (element1 && element2) {
      const element: TableSource = {
        title: elementName,
        unity: '',
        displayedUnity: '%',
        endColor: true,
        columns: {}
      };
      this.columnsList.forEach((column: string) => {
        element.columns[column] = (Math.pow(this.columnValue(element2.columns[column])
          / this.columnValue(element1.columns[column]), (1 / 5)) - 1) * 100;
      });
      return element;
    } else {
      return {
        title: elementName,
        unity: '',
        columns: {}
      };
    }
  }

  private columnValue(column: any): number {
    if (!column) {
      return column;
    }
    if (column?.highlightedData) {
      return Number(column.highlightedData);
    }
    return Number(column);
  }
}
