/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfigService, ConnectedUserService, LogService } from '.';
import { SeminarEventInfo } from '../generated/model/seminar/seminarEventInfo';
import { HubService } from './abstract.hub.service';
import { CaptainSpeakingTransition } from '../generated/model/seminar';

@Injectable({
  providedIn: 'root'
})
export class SeminarService extends HubService {
  protected override console = LogService.initialize('SeminarService');

  private _toolUserNotification$: Subject<SeminarEventInfo>;
  private _teamNotification$: Subject<SeminarEventInfo>;
  private _teamUserNotification$: Subject<SeminarEventInfo>;
  private _challengeNotification$: Subject<SeminarEventInfo>;
  private _countdownNotification$: Subject<SeminarEventInfo>;
  private _challengeInstructorNotification$: Subject<SeminarEventInfo>;
  private _captainSpeakingNotification$: Subject<SeminarEventInfo>;

  constructor(configService: ConfigService, private userService: ConnectedUserService) {
    super();
    this.console.log(`constructor()`);
    const { seminarBaseUrl } = configService.config;
    this._baseUrl = seminarBaseUrl;

    this._toolUserNotification$ = new Subject<SeminarEventInfo>();
    this._teamNotification$ = new Subject<SeminarEventInfo>();
    this._teamUserNotification$ = new Subject<SeminarEventInfo>();
    this._challengeNotification$ = new Subject<SeminarEventInfo>();
    this._countdownNotification$ = new Subject<SeminarEventInfo>();
    this._challengeInstructorNotification$ = new Subject<SeminarEventInfo>();
    this._captainSpeakingNotification$ = new Subject<SeminarEventInfo>();

    this.connect('Dashboard');
  }

  public get toolUserNotification$(): Subject<SeminarEventInfo> {
    return this._toolUserNotification$;
  }

  public get teamNotification$(): Subject<SeminarEventInfo> {
    return this._teamNotification$;
  }

  public get teamUserNotification$(): Subject<SeminarEventInfo> {
    return this._teamUserNotification$;
  }

  public get challengeNotification$(): Subject<SeminarEventInfo> {
    return this._challengeNotification$;
  }

  public get countdownNotification$(): Subject<SeminarEventInfo> {
    return this._countdownNotification$;
  }

  public get challengeInstructorNotification$(): Subject<SeminarEventInfo> {
    return this._challengeInstructorNotification$;
  }

  public get captainSpeakingNotification$(): Subject<SeminarEventInfo> {
    return this._captainSpeakingNotification$;
  }

  public async sendCaptainSpeakingTransition(captainSpeakingTransition: CaptainSpeakingTransition): Promise<boolean> {
    captainSpeakingTransition.when = new Date();
    captainSpeakingTransition.who =  this.userService.user;

    this.console.log(`sendCaptainSpeakingTransition`, captainSpeakingTransition);

    if (this.hubConnection) {
      try {
        await this.hubConnection.invoke('Transition', captainSpeakingTransition);
        return Promise.resolve(true);
      } catch (error) {
        console.error('Transition:', error);
        window.alert(error); // TODO@NST
      }
    }

    return Promise.resolve(false);
  }

  protected getUrl(instanceId: string): string {
    return  `${this._baseUrl}/seminarhub?instanceId=${instanceId}`;
  }

  protected onHubConnectionBuilt(): void {
    if (this.hubConnection) {
      {
        const event = 'Team';
        this.hubConnection.on(event, message => {
          this.console.log('hubConnection.on', event, message);
          this._teamNotification$.next(message);
        });
      }

      {
        const event = 'ToolUser';
        this.hubConnection.on(event, message => {
          this.console.log('hubConnection.on', event, message);
          this._toolUserNotification$.next(message);
        });
      }

      {
        const event = 'TeamUser';
        this.hubConnection.on(event, message => {
          this.console.log('hubConnection.on', event, message);
          this._teamUserNotification$.next(message);
        });
      }

      {
        const event = 'Challenge';
        this.hubConnection.on(event, message => {
          this.console.log('hubConnection.on', event, message);
          this._challengeNotification$.next(message);
        });
      }

      {
        const event = 'ChallengeInstructor';
        this.hubConnection.on(event, message => {
          this.console.log('hubConnection.on', event, message);
          this._challengeInstructorNotification$.next(message);
        });
      }

      {
        const event = 'CaptainSpeaking';
        this.hubConnection.on(event, message => {
          this.console.log('hubConnection.on', event, message);
          this._captainSpeakingNotification$.next(message);
        });
      }

      {
        const event = 'Countdown';
        this.hubConnection.on(event, message => {
          this.console.log('hubConnection.on', event, message);
          this._countdownNotification$.next(message);
        });
      }
    }
  }
}
